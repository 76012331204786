<template>
  <div>
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker
        :columns="items[active].columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-form>
      <van-cell-group v-for="(item, i) in items" :key="i">
        <van-cell v-if="item.type === 'cell'" :title="item.label" is-link />
        <van-field
          v-if="item.type === 'picker'"
          v-model="item.value"
          is-link
          readonly
          name="picker"
          :label="item.label"
          placeholder="请选择"
          @click="onClikPicker(item, i)"
        />
        <van-field
          v-if="item.type === 'input'"
          v-model="item.value"
          name="用户名"
          :label="item.label"
          :placeholder="item.placeholder"
        />
      </van-cell-group>

      <div style="margin: 16px">
        <van-button block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { ref } from "vue";

export default {
  setup() {
    const active = ref(1);
    const items = ref([
      {
        label: "经办银行",
        type: "picker",
        columns: ["中国工商银行股份有限公司咸阳世纪大道支行"],
        value: "中国工商银行股份有限公司咸阳世纪大道支行",
      },
      {
        label: "业务发生地",
        type: "picker",
        columns: ["陕西省-咸阳市-渭城区"],
        value: "陕西省-咸阳市-渭城区",
      },
      {
        label: "业务类型",
        type: "picker",
        columns: ["保证"],
        value: "保证",
      },
      {
        label: "业务品种",
        type: "picker",
        columns: ["光伏"],
        value: "光伏",
      },
      {
        label: "贷款期限",
        type: "picker",
        columns: ["96期"],
        value: "96期",
      },
      {
        label: "业务员",
        type: "picker",
        columns: [],
        value: "",
      },
      {
        label: "经销商",
        type: "picker",
        columns: [],
        value: "",
      },
      {
        label: "安装品牌",
        type: "input",
        value: "",
        placeholder: "请输入安装品牌",
      },
      {
        label: "安装型号",
        type: "input",
        value: "",
        placeholder: "请输入安装型号",
      },
      {
        label: "安装块数",
        type: "input",
        value: "",
        placeholder: "请输入安装块数",
      },
      {
        label: "安装瓦数",
        type: "input",
        value: "",
        placeholder: "请输入安装瓦数",
      },
      {
        label: "全款金额",
        type: "input",
        value: "",
        placeholder: "请输入全款金额",
      },
      {
        label: "贷款金额",
        type: "input",
        value: "",
        placeholder: "请输入贷款金额",
      },
      {
        label: "手机号码",
        type: "input",
        value: "",
        placeholder: "请输入客户手机号码",
      },
      {
        label: "工商卡号",
        type: "input",
        value: "",
        placeholder: "请输入客户工商卡号",
      },
    ]);
    const showPicker = ref(false);

    const onClikPicker = (item, i) => {
      active.value = i;
      showPicker.value = true;
    };

    const onConfirm = (value) => {
      result.value = value;
      showPicker.value = false;
    };

    return {
      items,
      active,
      onClikPicker,
      onConfirm,
      showPicker,
    };
  },
};
</script>


<style lang="scss" scoped>
.btns {
  padding: 10px;
  margin-top: 20px;
}
</style>